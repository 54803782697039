<template>
  <div :class="{ 'c-dark-theme': true, animated: false, fadeIn: true }">
      <!-- 탱크재고 조회 --------------------------------------------------------------------------------------------->

      <BCard header-tag="header" footer-tag="footer">

        <div slot="header">
          <BIconServer/> <strong> 노이즈 조회 </strong>
          <div class="card-header-actions">
            <small class="text-muted">데이터를 조회합니다.</small>
          </div>
        </div>

        <BRow class="mb-2">
          <BCol>
            <BInputGroup size="sm">
              <BInputGroupAppend>
                <BFormSelect size="sm" v-model="tid" @input="getTankData" :options="tankOpts" style="background-color:#2f303a"/>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm">
              <BFormInput type="date" v-model="fromDts" @input="getTankData"></BFormInput>
              <BFormInput type="date" v-model="toDts" @input="getTankData"></BFormInput>
            </BInputGroup>
          </BCol>
          <BCol sm="2" class="text-right">
            <BInputGroup size="sm">
              <BFormSelect size="sm" v-model="$store.state.numOfTankStockItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
              <BInputGroupAppend>
                <BButtonGroup>
                  <BButton size="sm" variant="primary" @click="getTankData"><BIconArrowRepeat/></BButton>
                </BButtonGroup>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol style="min-height:400px">
            <vue-excel-editor
              v-model="rows"
              ref="excelGrid"
              width="100%"
              class="mb-1"
              :page="$store.state.numOfTankStockItem"
              :readonly-style="{backgroundColor:'#4F5040'}"
              @select="selectRecord"
              :localized-label="editorLabel"
              filter-row>
              <vue-excel-column field="_id"        type="string"   key-field invisible/>
              <vue-excel-column field="dt"         type="string"   width="150px" :label="colNm['dt']" :to-text="toLocalTimeSec" readonly/>
              <vue-excel-column field="mid"        type="string"   width="80px" :label="colNm['mid']" readonly/>

              <vue-excel-column field="oh"        type="number"   width="72px" :label="colNm['oh']"  readonly/>
              <vue-excel-column field="wh"        type="number"   width="72px" :label="colNm['wh']"  readonly/>

              <vue-excel-column field="ohr"        type="number"   width="72px" :label="colNm['ohr']"  readonly/>
              <vue-excel-column field="whr"        type="number"   width="72px" :label="colNm['whr']"  readonly/>

              <vue-excel-column field="tm1"        type="number"   width="60px" :label="colNm['tm1']" readonly/>
              <vue-excel-column field="tm2"        type="number"   width="60px" :label="colNm['tm2']" readonly/>
              <vue-excel-column field="tm3"        type="number"   width="60px" :label="colNm['tm3']" readonly/>
              <vue-excel-column field="tm4"        type="number"   width="60px" :label="colNm['tm4']" readonly/>
              <vue-excel-column field="tm5"        type="number"   width="60px" :label="colNm['tm5']" readonly/>
              <vue-excel-column field="tm6"        type="number"   width="60px" :label="colNm['tm6']" readonly/>

              <vue-excel-column field="messages"   type="string"   width="800px" :label="colNm['text']" readonly/>
<!--              <vue-excel-column field="encStr"   type="string"   width="600px" :label="colNm['encStr']" readonly/>-->
<!--              <vue-excel-column field="decStr"   type="string"   width="600px" :label="colNm['decStr']" readonly/>-->


            </vue-excel-editor>
          </BCol>
        </BRow>


      </BCard>


    <div v-if="tank">
      <CModal ref="download-modal"
              color="warning"
              title="다운로드 사유 입력"
              :show.sync="downloadModalShow">
        <BInputGroup>
          <BFormInput v-model="downloadReason" debounce="6000" placeholder="5자이상 입력"></BFormInput>
          <BInputGroupAppend>
            <BButton variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </CModal>
    </div>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  accessLogging,
  random, iosDtToLocal,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import {ExcelEditorLabel} from '@/common/constants';




const _packetColMap = {
  dt            : '측정일시',   // T_CREATE_DATE
  tid           : '탱크번호', // T_TANK 탱크아이디
  mid           : '장비번호',
  acd           : '지역', // Work Group Code (지역코드)
  am            : '측정구분', // T_TYPE =  A=ATG,M=MTG
  ocd           : '유종코드', // T_OIL 유종코드
  use           : '사용용도', // T_USE 사용용도
  tv            : '탱크용량', // T_MAX_VOLUME = tank.tankVolume
  tvp           : '충족률', // T_MAX_VOLUME = tank.tankVolume
  trv           : '실용량', // T_REAL_VOLUME = tank.tankRealVol
  oh            : '유위o', // T_OIL_HEIGHT = tlgPacket.oh = 유위
  wh            : '수위o', // T_WATER_HEIGHT = tlgPacket.wh = 수위
  ohr           : '유위1', // T_OIL_HEIGHT = tlgPacket.ohr = 유위(보정높이)
  whr           : '수위1', // T_WATER_HEIGHT = tlgPacket.whr = 수위(보정높이)
  ov            : '유량', // T_OIL_VOLUME = tlgPacket.ov = 유량
  wv            : '수분', // T_WATER_VOLUME = tlgPacket.wv = 수분량
  ovm           : '재고', // T_MEASURE_VOLUME = tlgPacket.ovm = 실재고
  ovc           : '환산재고', // T_CONVERSION = tlgPacket.ovc = 체적 환산유량
  ovcr          : '보정재고', // T_REVISION = tlgPacket.ovc = 체적 환산유량 + 보정
  tm1           : '온도1', // T_TEMP1 = tlgPacket.tm1
  tm2           : '온도2', // T_TEMP2 = tlgPacket.tm2
  tm3           : '온도3', // T_TEMP3 = tlgPacket.tm3
  tm4           : '온도4', // T_TEMP4 = tlgPacket.tm4
  tm5           : '온도5', // T_TEMP5 = tlgPacket.tm5
  tm6           : '온도6', // T_TEMP6 = tlgPacket.tm6
  tm7           : '온도7', // T_TEMP7 = tlgPacket.tm7
  tm8           : '온도8', // T_TEMP8 = tlgPacket.tm8
  tm9           : '온도9',
  avgTm         : '평균온도', // T_AVERAGE_TEMP
  maxTm         : '최고온도', // T_MAX_TEMP
  minTm         : '최저온도', // T_MIN_TEMP
  btr           : '배터리', // T_BATTERY = tlgPacket.btr
  rsi           : '신호세기', // T_RSSI = tlgPacket.rsi
  encStr        : '암호문',
  decStr        : '평문',
  messages      : '메시지', // T_COMMENT
  isEvt         : '이벤트',
  isErr         : '에러',
};


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "NoiseData",
  components: {
  },
  data () {
    return {
      selectedRow: null,
      fromDts: moment().subtract(1,'day').format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      tid: '',
      colNm: _packetColMap,
      tank: null,

      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      tankKey: random(0,100000),
      tankOpts : this.$store.state.tanks['opts'],
      maps: this.$store.state.codeMaps,

      tanks: this.$store.state.tanks['codes'],

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,
      oilCodeMap  : {},
      arCodeMap  : this.$store.state.area['map'],
      arCodeOpts  : this.$store.state.area['opts'],
      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      // console.log( 'tank options--->', this.tankOpts );
      await this.getTankData();
      console.log('[TankData] created-------------');
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    // console.log("--- TankData mounted---------------------");
    // below is not work!
  },

  methods: {

    async getTankData(){
      try{
        if(!this.tid){
          this.tid = this.tankOpts[1].value;
        }

        let qry = '';
        this.paramMap = {
          fromDts: this.fromDts,
          toDts: this.toDts
        };
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        // console.log( "query-string ---------- ", qry );

        this.rows = [];
        const r = await apiCall('get', `/api/inventory/tank-noise-data/${this.tid}?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
        }


      }catch(err){
        this.toastError(err);
      }

    },

    async exportExcel(){
      // console.log('------------exportExcel----------')
      try{

        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if(!numRecs){
          this.$refs['download-modal'].hide();
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.",'선택 레코드 없음');
        }

        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await this.alertWarn( "다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }


        const {currentRoute} = this.$router;
        const {fields} = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop()+'-'+moment().format('YYYY-MM-DD');
        const labels = fields.map(i=>{ return i.label })
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );

        if(rs===true){
          this.$refs['excelGrid'].getSelectedRecords().map(i=>{
            i.dt = iosDtToLocal(i.dt);
          })

          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        }else{
          await this.alertDanger("ERROR: "+rs.message);
          return false;
        }




      }catch(err){
        console.error( 'exportExcel error', err);
      }finally{
        this.$refs['download-modal'].hide();

      }
    },

    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.selectedRow = null;
        return;
      }

      this.selectedRow = this.$refs['excelGrid'].getSelectedRecords().pop();
    },

  }
}
</script>
